import React, {Component} from 'react';
import StageSwitcher from "../StageSwitcher";

import firebase from 'firebase/app';
import 'firebase/database';

import {default as StageSwicherBtn} from "../Components/Buttons/StageSwitcher";
import {default as ResultsBtn} from "../Components/Buttons/Results";
import {default as RealtimeResultsBtn} from "../Components/Buttons/RealtimeResults";
import {default as TimeTableBtn} from "../Components/Buttons/TimeTable";
import {default as BackBtn} from "../Components/Buttons/Back";


class LiveContent extends Component
{
    constructor(props) {
        super(props);

        this.element = null;
        this.firebaseData = {};
        this.data = props.data;
        this.variableElements = props.variableElements;
        this.lastResultsId = null;
        this.lastTimeTableId = null;

        this.firebasePanelRef = null;
        this.firebaseResultsRef = null;
        this.firebaseTimetableRef = null;

        if (this.data.results !== undefined && this.data.results !== null && this.data.results !== false) {
            this.lastReadedResultsId = localStorage.getItem(this.data.results.firebase_id + '/lastResultsId') || null;
        } else {
            this.lastReadedResultsId = null;
        }

        if (this.data.timetable !== undefined && this.data.timetable !== null && this.data.timetable !== false) {
            this.lastReadedTimeTableId = localStorage.getItem(this.data.timetable.firebase_id + '/lastTimeTableId') || null;
            this.lastReadedTimeTableId = this.lastReadedTimeTableId !== null ? parseInt(this.lastReadedTimeTableId) : null;
        } else {
            this.lastReadedTimeTableId = null;
        }

        this.state = {
            stage : 0,
            hasNewResults : false,
            newTimeTables : false,

            // components
            showStageSwitcher : false,

            // Buttons
            resultsBtn : false,
            stageSwitcherBtn : false,
            timeTableBtn : false
        };

        this.firebaseOnValuePanelNode = this.firebaseOnValuePanelNode.bind(this);
        this.firebaseOnValueResultsNode = this.firebaseOnValueResultsNode.bind(this);
        this.firebaseOnValueTimeTableNode = this.firebaseOnValueTimeTableNode.bind(this);
        this.showStageSwitcher = this.showStageSwitcher.bind(this);
        this.hideStageSwitcher = this.hideStageSwitcher.bind(this);
        this.markNewResultsReaded = this.markNewResultsReaded.bind(this);
        this.markNewTimeTableReaded = this.markNewTimeTableReaded.bind(this);
        this.updateData = this.updateData.bind(this);
        this.onStageClick = this.onStageClick.bind(this);
    }

    componentDidMount() {
        // init element and display it
        this.element = document.getElementById('live_content');
        this.element.style.display = 'block';

        // show stage switcher button if needed
        if (this.data.stages.length > 1) {
            this.setState({
                stageSwitcherBtn : true
            });
        }

        // start listen firebase
        let database = firebase.database();
        this.firebasePanelRef = database.ref('panel/' + this.data.firebase_id);
        this.firebasePanelRef.on('value', this.firebaseOnValuePanelNode);

        if (this.data.results !== undefined && this.data.results !== null && this.data.results !== false) {
            this.firebaseResultsRef = database.ref('results/' + this.data.results.firebase_id);
            this.firebaseResultsRef.on('value', this.firebaseOnValueResultsNode);
        }

        if (this.data.timetable !== undefined && this.data.timetable !== null && this.data.timetable !== false) {
            this.firebaseTimetableRef = database.ref('timetable/' + this.data.timetable.firebase_id);
            this.firebaseTimetableRef.on('value', this.firebaseOnValueTimeTableNode);
        }
    }

    componentWillUnmount() {
        if (this.element) {
            this.element.style.display = 'none';
        }

        this.firebasePanelRef.off();
        this.firebaseResultsRef.off();
        this.firebaseTimetableRef.off();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.stage !== prevState.stage) {
            this.updateData(this.firebaseData);
        }
    }

    firebaseOnValuePanelNode(snapshot) {
        this.firebaseData = snapshot.val();
        this.updateData(snapshot.val());
    }

    firebaseOnValueResultsNode(snapshot) {
        let value = snapshot.val();

        if (value === null || value.data === undefined || value.published__app === undefined) {
            return;
        }

        this.setState({
            resultsBtn : !(Object.keys(value.data).length === 0
                || value.published__app === undefined
                || value.published__app === null)
        });

        if ( value.published__app !== this.lastResultsId ) {
            this.lastResultsId = value.published__app;

            if (this.lastResultsId !== this.lastReadedResultsId && this.data.results.realtime) {
                this.setState({
                    hasNewResults: true
                });
            }
        }
    }

    firebaseOnValueTimeTableNode(snapshot) {
        let value = snapshot.val();

        if (value === null || value.data === undefined) {
            return;
        }

        this.setState({
            timeTableBtn : !(Object.keys(value.data).length === 0)
        });

        if ( value.lastRoundId !== this.lastTimeTableId ) {
            this.lastTimeTableId = value.lastRoundId;

            if (this.lastTimeTableId !== this.lastReadedTimeTableId && this.data.timetable.realtime) {
                this.setState({
                    newTimeTables: true
                });
            }
        }
    }

    updateData(data) {
        let App = this;
        let stageKey = this.data.stages[this.state.stage].firebase_id;

        if (data !== null && data[stageKey] !== undefined) {
            let variables = this.data.template.variables;
            variables._stage = this.data.stages[this.state.stage].title;

            variables.forEach(variable => {
                let nodes = App.variableElements[variable];

                if (nodes.length > 0) {
                    nodes.forEach((node) => {
                        if (variable === '_stage') {
                            node.innerHTML = this.data.stages[this.state.stage].title;
                        } else if (data[stageKey].data[variable.replace('.', '^')] !== undefined) {
                            node.innerHTML = data[stageKey].data[variable.replace('.', '^')];
                        } else {
                            node.innerHTML = "";
                        }
                    });
                }
            });

            if ("function" === typeof window.update) {
                let funcData = {};
                Object.keys(data[stageKey].data).forEach(variable => {
                    funcData[variable.replace('^', '.')] = data[stageKey].data[variable];
                });

                window.update(funcData);
            }
        }
    }

    markNewResultsReaded() {
        localStorage.setItem(this.data.results.firebase_id + '/lastResultsId', this.lastResultsId);
        this.lastReadedResultsId = this.lastResultsId;

        this.setState({
            hasNewResults: false
        });
    }

    markNewTimeTableReaded() {
        localStorage.setItem(this.data.results.firebase_id + '/lastTimeTableId', this.lastTimeTableId);
        this.lastReadedTimeTableId = this.lastTimeTableId;

        this.setState({
            hasNewResults: false
        });
    }

    showStageSwitcher() {
        this.setState({
            showStageSwitcher: true
        });
    }

    hideStageSwitcher() {
        this.setState({
            showStageSwitcher: false
        });
    }

    onStageClick(stageId) {
        this.setState({
            showStageSwitcher: false,
            stage: stageId
        });
    }

    render() {
        return <div>
            <StageSwicherBtn onClick={this.showStageSwitcher} visible={this.state.stageSwitcherBtn} />
            <RealtimeResultsBtn onClick={this.markNewResultsReaded} visible={this.state.resultsBtn} hasNewResults={this.state.hasNewResults} isRealtime={this.data.results ? this.data.results.realtime : false} />
            <TimeTableBtn onClick={this.markNewTimeTableReaded} visible={this.state.timeTableBtn} hasNewRows={this.state.newTimeTables} />
            <ResultsBtn visible={false} />
            <BackBtn visible={false} />
            {this.state.showStageSwitcher ? <StageSwitcher stages={this.data.stages} currentStage={this.state.stage} hideStageSwitcher={this.hideStageSwitcher} onStageClick={this.onStageClick} /> : null}
        </div>;
    }
}

export default LiveContent;
import React, { Component } from 'react';

class ResultsTableFinal extends Component
{
    render() {
        return <table className={"results-table results-table--classic"}>
            <thead>
                <tr>
                    <th className={"results-table__column results-table__column--place"}>Place</th>
                    <th colSpan={2} className={"results-table__column results-table__column--competitor"}>Competitor</th>
                </tr>
            </thead>
            <tbody>
            { this.props.results.map((result, key) => {
                return <tr key={key}>
                    <td className={"results-table__column results-table__column--place"}>{result.pos}</td>
                    <td className={"results-table__column results-table__column--std"}>{result.iso ? <img src={`${process.env.PUBLIC_URL}/img/flags/` + result.iso.toLowerCase() + '.svg'} alt={result.country} /> : <span className="empty-flag" /> } {result.stn}</td>
                    <td className={"results-table__column results-table__column--competitor"}>{result.competitior}</td>
                </tr>
            }) }
            </tbody>
        </table>
    }
}

export default ResultsTableFinal;
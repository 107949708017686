import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import cookies from 'react-cookies';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://3d1044706d4f487cae1f63e2daa364c9@sentry.io/1504598"});

let date = new Date();
let v = date.getFullYear().toString() + date.getMonth().toString() + date.getDate();
let appId = process.env.REACT_APP_MOBILE_VIEWER_APP_ID !== undefined && process.env.REACT_APP_MOBILE_VIEWER_APP_ID !== '' ? '&app_id=' + process.env.REACT_APP_MOBILE_VIEWER_APP_ID : '';

fetch(`${process.env.REACT_APP_MOBILE_VIEWER_API_URL}/app-version?v=${v}${appId}`)
    .then(response => response.json())
    .then(data => {
        if (data.version !== cookies.load('app-version')) {
            date.setMonth(date.getMonth() + 1);
            date.setDate(1);
            cookies.save('app-version', data.version, { path : '/', expires : date });
            cookies.remove('data-cached');
            localStorage.removeItem('data');
            window.location.reload(true);
        }
    });


firebase.initializeApp({
    apiKey: "AIzaSyAOqDMwBik0JIhBaHattt7qgHiqGXyfMm4",
    authDomain: "crp-titulkovac.firebaseapp.com",
    databaseURL: "https://crp-titulkovac.firebaseio.com",
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
